/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import "./filtering.css";

import { axiosInstance } from "../../../../services/AxiosInstance";
import { FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        type="search"
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Company = () => {
  const keys = [
    "company_name",
    "owner_name",
    "email",
    "phone_no",
    "description",
  ];
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const COLUMNS = [
    ...keys.map((key) => ({
      Header: key.replace(/_/g, " "),
      Footer: key.replace(/_/g, " "),
      accessor: key,
      Filter: ColumnFilter,
    })),
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        return (
          <>
            {value === "paid" ? (
              <span className="badge bg-success text-capitalize">{value}</span>
            ) : (
              <span className="badge bg-danger text-capitalize">{value}</span>
            )}
          </>
        );
      },
    },
    {
      Header: "Action",
      Footer: "Action",
      // accessor: "status",
      // Filter: ColumnFilter,
      Cell: ({ row }) => {
        return (
          <Link to={`${row?.original.id}`}>
            <FaUsers className="text-primary" size={30} />
          </Link>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;

  const getCompany = () => {
    setLoader(true);
    axiosInstance
      .get("admin/company/view")
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error, "song Error");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  // const getModuleID = (id) => {
  //   setLoader(true);
  //   axiosInstance
  //     .get(`admin/module/edit/${id}`)
  //     .then(function (response) {
  //       console.log(response);
  //       if (response?.data.status === true) {
  //         setModuleId(response?.data?.data);
  //         setLoader(false);
  //         onEditShow();
  //       } else {
  //         setLoader(false);
  //       }
  //     })
  //     .catch(function (error) {
  //       setLoader(false);
  //       console.log(error, "song Error");
  //       window.scrollTo({
  //         top: 0,
  //         behavior: "smooth",
  //       });
  //     });
  // };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Company</h4>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Company;
