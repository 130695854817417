import React, { useState } from "react";
import { axiosInstance } from "../../services/AxiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
//import { Link } from "react-router-dom";
// image
//import logo from "../../images/logo-full.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isOpt, setIsotp] = useState(false);
  const [fieldvalue, setFieldValue] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    token: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFieldValue({ ...fieldvalue, [name]: value });
  };
  const submitEmail = (e) => {
    e.preventDefault();
    setLoader(true);
    const formdata = new FormData();
    formdata.append("email", fieldvalue.email);
    axiosInstance
      .post("admin/sent/email", formdata)
      .then((response) => {
        const { status, message } = response.data;
        if (status) {
          toast.success(message);
          setIsotp(true);
          setLoader(false);
        } else {
          setLoader(false);
          toast.error(message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  const submitPassword = (e) => {
    e.preventDefault();
    setLoader(true);
    const formdata = new FormData();
    formdata.append("token", fieldvalue.token);
    formdata.append("password", fieldvalue.password);
    formdata.append("password_confirmation", fieldvalue.password_confirmation);
    axiosInstance
      .post("admin/reset/password", formdata)
      .then((response) => {
        const { status, message } = response.data;
        if (status) {
          toast.success(message);
          navigate("/login");
          setLoader(false);
        } else {
          setLoader(false);
          toast.error(message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  return (
    <>
      {loader && <div className="loader" />}
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        {/* <Link to="/dashboard">
							<img src={logo} alt="" />
						</Link> */}
                      </div>
                      <h4 className="text-center mb-4 ">Forgot Password</h4>
                      {isOpt ? (
                        <form onSubmit={submitPassword}>
                          <div className="form-group mb-3">
                            <label className="">
                              <strong>Otp</strong>
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              name="token"
                              placeholder="Type Your Otp"
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label className="">
                              <strong>Password</strong>
                            </label>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder="Type Your Password"
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label className="">
                              <strong>Confirm Password</strong>
                            </label>
                            <input
                              type="password"
                              name="password_confirmation"
                              className="form-control"
                              placeholder="Type Your Confirm Password"
                              onChange={handleInputChange}
                            />
                          </div>

                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Unlock
                            </button>
                          </div>
                        </form>
                      ) : (
                        <form onSubmit={(e) => submitEmail(e)}>
                          <div className="form-group mb-3">
                            <label className="">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Your Email....."
                              name="email"
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Send
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
