/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import "./filtering.css";

import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../services/AxiosInstance";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        type="search"
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Module = () => {
  const keys = ["module_name"];
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const [editshow, setEditShow] = useState(false);
  const onEditShow = () => setEditShow(true);
  const onEditHide = () => setEditShow(false);
  const [loader, setLoader] = useState(false);
  const [module_name, setModule_name] = useState({});
  const [moduleId, setModuleId] = useState(null);

  const COLUMNS = [
    ...keys.map((key) => ({
      Header: key.replace(/_/g, " "),
      Footer: key.replace(/_/g, " "),
      accessor: key,
      Filter: ColumnFilter,
    })),
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }) => {
        const DeleteModule = (id) => {
          setLoader(true);
          try {
            axiosInstance
              .get(`admin/module/delete/${id}`)
              .then((response) => {
                if (response.data.status === true) {
                  getModule();
                  toast.success(response.data.message);
                  setLoader(false);
                } else {
                  toast.error("SomeThing went Wrong");
                  setLoader(false);
                }
              })
              .catch((errors) => {
                toast.error("SomeThing went Wrong");
                setLoader(false);
              });
          } catch (error) {
            toast.error(error);
          }
        };
        return (
          <>
            <div className="d-flex gap-2">
              <button
                onClick={() => {
                  getModuleID(row.original.id);
                }}
                title="Edit"
                className={`btn btn-outline-success btn-sm rounded`}
              >
                <i className="fa fa-pen" />
              </button>
              <button
                onClick={() => {
                  DeleteModule(row.original.id);
                }}
                title="Edit"
                className={`btn btn-outline-danger btn-sm rounded`}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;

  const getModule = () => {
    setLoader(true);
    axiosInstance
      .get("admin/module/view")
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error, "song Error");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  const getModuleID = (id) => {
    setLoader(true);
    axiosInstance
      .get(`admin/module/edit/${id}`)
      .then(function (response) {
        console.log(response);
        if (response?.data.status === true) {
          setModuleId(response?.data?.data);
          setLoader(false);
          onEditShow();
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error, "song Error");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  const addModule = () => {
    setLoader(true);
    var formdata = new FormData();
    formdata.append("module_name", module_name);

    axiosInstance
      .post(`admin/module/store`, formdata)
      .then((response) => {
        if (response.data.status) {
          getModule();
          onHide();
          toast.success(response.data.message);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  const updateModule = (id) => {
    setLoader(true);
    var formdata = new FormData();
    formdata.append("module_name", module_name);

    axiosInstance
      .post(`admin/module/update/${id}`, formdata)
      .then((response) => {
        if (response.data.status) {
          getModule();
          onEditHide();
          toast.success("Profile Updated Successfully!");
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    getModule();
  }, []);
  const handleInputChange = (e) => {
    const { value } = e.target;
    setModule_name(value);
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Module</h4>
            <button className="btn btn-primary float-end" onClick={onShow}>
              Add Module
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Add Modal */}
      <Modal show={show} onHide={onHide} size="sm">
        <Modal.Header closeButton>Add Module</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className={`mb-3 col-12`}>
                <h5 className="text-capitalize">Module Name</h5>
                <input
                  type={"text"}
                  name={"module_name"}
                  className="form-control"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Close
          </Button>
          <Button variant="success" onClick={addModule}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add Modal Close */}
      {/* Update Modal Start */}
      <Modal show={editshow} onHide={onEditHide} size="sm">
        <Modal.Header closeButton>Module Detail</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className={`mb-3 col-12`}>
                <h5 className="text-capitalize">Module Name</h5>
                <input
                  type={"text"}
                  name={"module_name"}
                  defaultValue={moduleId?.module_name}
                  className="form-control"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onEditHide}>
            Close
          </Button>
          <Button variant="success" onClick={() => updateModule(moduleId.id)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update Modal Close */}
    </>
  );
};
export default Module;
