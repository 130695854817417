import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import PostsReducer from "./reducers/PostsReducer";
import todoReducers from "./reducers/Reducers";
import driverSlice from "./reducers/driver";
import moduleSlice from "./reducers/module";
import submoduleSlice from "./reducers/submodule";
import companySlice from "./reducers/company";
import userSlice from "./reducers/user";

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage,
};

// Combine your reducers with redux-persist
const rootReducer = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  driver: driverSlice,
  module: moduleSlice,
  submodule: submoduleSlice,
  company: companySlice,
  user: userSlice,
  form: reduxFormReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with persistedReducer
export const store = createStore(
  persistedReducer,
  composeEnhancers(middleware)
);

// Create the persisted store
export const persistor = persistStore(store);
