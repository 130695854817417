import React from "react";

const Footer = () => {
  var d = new Date();
  const year = d.getFullYear();
  console.log(d,year)
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © Designed &amp; Developed by{" "}
          <a
            href="http://HnhTechSolutions.com/"
            target="_blank"
            rel="noreferrer"
          >
            HnhTechSolutions
          </a>{" "}
          {year}
        </p>
      </div>
    </div>
  );
};

export default Footer;
